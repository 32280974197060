:root {
  --black: hsla(0, 0%, 5%, 1);
  --black-70: hsla(0, 0%, 5%, 0.7);
  --black-40: hsla(0, 0%, 5%, 0.4);
  --black-20: hsla(0, 0%, 5%, 0.2);

  --white: #ffffff;
  --gray: #f4f4f4;

  --night: hsla(226, 67%, 31%, 1);
  --night-70: hsla(226, 67%, 31%, 0.7);
  --night-40: hsla(226, 67%, 31%, 0.4);

  --sand: hsla(36, 85%, 54%);
  --sand-70: hsla(36, 85%, 54%, 0.7);
  --sand-40: hsla(36, 85%, 54%, 0.4);

  --antique: #ffefd7;

  --error: red;
  --warning: orange;
}
