.downloads {
  display: flex;
  flex-direction: column;
  gap: var(--p);
  margin-block: var(--margin-s);
  padding: var(--margin-s);

  border-top: 0.1em solid var(--sand);
  border-bottom: 0.1em solid var(--sand);
}

.download {
  margin-block: 0;
}

.download__caption {
  line-height: 1;
}
