:root {
  --gap: 1rem;
  --p: 1rem;
  --spacing: 0.6rem;
  --spacing-s: 0.2rem;

  --c: 5.5rem;
  --c3: 20rem;
  --c4: 26.5rem;
  --c5: 33.5rem;
  --c6: 40.5rem;
  --c7: 47.5rem;
  --c8: 54.5rem;
  --c10: 68.5rem;
  --c12-outer: 85.5rem;

  --c-prose: 44rem;

  --margin-s: 2rem;
  --margin: 3rem;
  --margin-l: 4rem;
  --margin-xl: 6rem;
}

@media (--media-md) {
  :root {
    --gap: 1rem;
    --p: 1.5rem;
    --p-m: 3rem;
    --p-l: 7.5rem;
    --p-xl: 9rem;
    --spacing: 0.75rem;
    --spacing-s: 0.33rem;

    --margin-s: 3rem;
    --margin: 5rem;
    --margin-l: 7.5rem;
    --margin-xl: 9.375rem;
  }
}

:root {
  --z-top: 5;
  --z-flips: 4;
  --z-menu: 3;
}

.container {
  width: 100%;
  max-width: var(--c12-outer);

  margin-left: auto;
  margin-right: auto;
  padding: 0 var(--p);

  &.prose {
    max-width: var(--c-prose);
  }

  &.reserved {
    max-width: var(--c8);
  }

  & > .container {
    padding-inline: 0;
  }
}

.main {
  flex-grow: 1;
}
