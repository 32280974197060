.pagination {
  margin-block: var(--margin-s);
  width: 100%;
  display: flex;
  justify-content: center;
}

.pagination__control {
  &.inactive {
    opacity: 0.2;
    cursor: unset;
    .icon {
      color: var(--black);
    }
  }
}

.pagination__control--left {
  margin-right: var(--margin);
}

.pagination__control--item {
  margin-inline: var(--spacing);
  &.current {
    font-weight: bold;
    color: var(--sand);
  }
}

.pagination__control--right {
  margin-left: var(--margin);
}
