.footer {
  margin-top: var(--margin-l);
  padding: var(--margin) 0;

  background: var(--antique);
  color: var(--black);
}

.fc__menu {
  display: flex;
  gap: var(--spacing);
}

.fc__top {
  display: flex;
  flex-wrap: wrap;
  gap: var(--p);
}

.fc__bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--p);
}

.fc__so-me {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--spacing);
}

.fc__left {
  flex: 1 1 var(--c4);
  display: flex;
  gap: var(--p);
  padding-bottom: 36px;
}

.fc__right {
  flex: 0 1 var(--c7);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--p);
}

.fc__logo {
  max-width: var(--c);
}

.zewo {
  max-width: 4rem;
}
