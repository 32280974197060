.mission-teaser {
  margin-block: var(--margin-s) var(--margin-l);

  .gallery {
    display: flex;
    flex-wrap: wrap-reverse;
    margin-block: var(--p);

    gap: var(--p);
    align-items: flex-end;

    .gallery__thumbs {
      flex: 1 1 auto;

      display: flex;
      flex-direction: column;
    }
    .gallery__thumb {
      text-align: left;
      padding: 1.5rem;
      &.active {
        background-color: var(--antique);
        border-radius: 1.5rem;
      }
      p {
        margin-bottom: 0;
      }
      .short-title {
        font-size: 1.25rem;
        font-weight: bold;
        .icon {
          --size: 0.8em;
        }
      }
    }
    .gallery__contents {
      flex: 1 1 var(--c7);
      margin-block: 0;
    }
    figure {
      margin-block: 0;
    }
    .gallery__content-wrap {
      margin-block: 0;
      display: flex;
      flex-direction: column;
      gap: var(--gap);
      border-radius: 0;
      background-color: unset;
      img {
        margin-inline: 0 auto;
        flex: 0 1 auto;
        border-radius: 0;
        min-width: var(--c3);
      }

      span .icon {
        color: var(--black);
      }
    }
  }
}
