.circle-span {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing);
}

.circle {
  --pill-bg: var(--sand);
  color: var(--white);
  background-color: var(--pill-bg);

  background-position: center;
  background-size: contain;

  padding: 0.4em;
  border-radius: 50%;

  width: 1.875em;
  height: 1.875em;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  &--night {
    --pill-bg: var(--night);
  }
}
