.gallery {
  margin-block: var(--margin);
}

.gallery__thumbs {
  display: flex;
  justify-content: center;
  gap: var(--gap);
}

.gallery__thumb {
  filter: grayscale(1);
  &.active {
    filter: unset;
  }
}

.gallery__contents {
  position: relative;

  height: 0px;
  margin-bottom: var(--margin-l);

  transition: height 0.1s ease-in-out;

  figure {
    margin-top: var(--margin-s);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    opacity: 0;
    visibility: hidden;

    &.active {
      transition: opacity 0.3s ease-in 0.1s;
      opacity: 1;
      visibility: visible;
    }
  }
}

.gallery__content-wrap {
  background-color: var(--antique);
  border-radius: 1.25em;
  overflow: hidden;

  img {
    border-radius: 0;
  }
}
