:root {
  --f3cc-background: var(--gray);
  --f3cc-foreground: var(--black);
  --f3cc-button-background: var(--black-20);
  --f3cc-button-foreground: var(--black);
  --f3cc-accept-background: var(--sand);
  --f3cc-accept-foreground: var(--black);
}

.f3cc {
  .f3cc-button {
    padding: 0;
    overflow: visible;
    border: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    color: inherit;
    background: none;
    cursor: pointer;
    appearance: none;

    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    user-select: none;
    line-height: 1;
    text-decoration: none;
    padding: 1em 1.5em;
    color: var(--night);
    border: 0.125em solid var(--night);
    cursor: pointer;
    font-weight: bold;
    transition: border-radius var(--general-transition-speed), background-color
      var(--general-transition-speed);
    border-radius: 0.75em;

    &:focus-visible,
    &:hover {
      color: var(--white);
      background-color: var(--night);
      opacity: 1;
    }
  }

  .f3cc-button.accept {
    color: var(--sand);
    border-color: var(--sand);
    background-color: unset;
    &:focus-visible,
    &:hover {
      color: var(--white);
      background-color: var(--sand);
      opacity: 1;
    }
  }

  .f3cc-embed {
    margin-bottom: var(--spacing);
  }
}
