.search {
  .search-text {
    & + .inline-icon {
      margin-top: 0;

      position: absolute;
      top: 0.5em;
      right: 0.33em;

      --size: 1.5em;
    }
  }
}

.results {
  .article-list {
    margin-block: 0;
  }
}

.results__publications {
  .publication {
    margin-bottom: 15px !important;
  }
}
