.generic-form {
  --padding: 0.5rem 0.75rem;

  .fields {
    display: flex;
    flex-wrap: wrap;
    gap: var(--p);
  }

  .field {
    flex: 1 0 100%;
    position: relative;
    &.half {
      flex: 1 1;
    }
    input,
    select {
      padding: var(--padding);
      width: 100%;
      outline: none;
      border: none;
      border-radius: 0;

      background-color: var(--gray);
      border-bottom: 0.2em solid var(--sand);

      &:focus-visible {
        /* border-bottom: 0.2em solid rgba(0, 0, 0, 0); */
        outline: 0.2em solid var(--sand);
      }
    }
  }
  .field.placeholder-labels {
    label {
      padding: var(--padding);
      position: absolute;
      top: calc(-2em);
      font-size: 0.8rem;
      left: 0;
      opacity: 0;
      transition: opacity 0.2s ease-in;

      &.required {
        &::after {
          content: " *";
        }
      }
    }

    input:not(:placeholder-shown) + label {
      opacity: 1;
    }
  }

  button[type="submit"] {
    margin-top: var(--margin-s);
  }

  .radio-filter {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    label.button {
      padding: 0.5rem 2rem;
      border-radius: 1.25rem;
      font-weight: normal;
    }
    input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
    }
    input:checked + label {
      background-color: var(--night);
      color: var(--white);
    }
  }
  legend {
    padding: 0;
    margin-block: var(--spacing);
    font-weight: bold;
    font-size: 1.5rem;
  }

  .field.checkbox .field.radio {
    padding: var(--padding);
    display: flex;
    flex-direction: row;
    label {
      padding: 0;
      order: 2;
      margin-left: 0.2rem;
    }
  }

  .field.checkbox-multiple,
  .field.radio {
    flex-direction: column;
    label {
      order: 0;
    }
  }

  .field.checkbox,
  .field.checkbox-multiple,
  .field.radio {
    position: unset;
    display: flex;
    label {
      font-size: unset;
      position: unset;
      display: inline;
      opacity: 1;
    }
    & > div,
    input.checkbox,
    input.radio {
      order: 1;
    }
    input {
      display: inline;
      width: auto;
    }
  }
}

.generic-form,
.generic-form-message {
  margin-block: var(--margin-s);
}

/* custom form element overrides */
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .generic-form select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    border: none;
    outline: none;
    padding: 0;

    display: relative;
    cursor: pointer;

    background: no-repeat url(/static/assets/icons/select.svg);
    background-size: 1.2rem;
    background-position: 95% center;
  }
}

ul.errorlist {
  padding: var(--padding);
  padding-top: 0;
  li {
    font-size: 1rem;
    color: red;
  }
}
