.mo.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: var(--p);
  margin-block: var(--margin-s) var(--margin-l);

  .gallery__contents {
    max-width: var(--c7);
    width: 100%;
    margin-block: 0;
  }

  figure {
    margin-block: 0;
  }

  .gallery__content-wrap {
    width: 100%;
    max-width: var(--c7);
    display: flex;
    flex-wrap: wrap;
    img {
      padding: var(--p);
      flex: 0 1;
      margin: auto;
    }
    .gallery__content-body {
      flex: 1 0 var(--c4);
      padding: var(--p);
      margin-right: auto;

      h3 {
        margin-top: 0.5rem;
      }
      p {
        margin-bottom: 0.5rem;
      }
    }
  }
  .gallery__thumbs {
    flex: 1 1;
    min-width: var(--c3);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--c), 1fr));
    gap: var(--gap);

    .gallery__thumb {
      width: 5.5625rem;
      height: 5.5625rem;
      background-color: var(--gray);
      border-radius: 0.75rem;
      filter: none;

      &.active {
        background-color: var(--antique);
      }
    }

    img {
      margin: auto;
    }
  }
}
