a:not([class]) {
  scroll-margin: var(--margin);
  color: var(--sand);
  text-decoration: none;
  font-weight: bold;
}
/* anchor fix */
h1,
h2,
h3 {
  scroll-margin: var(--margin);
  & > a:not([class]) {
    color: unset;
    font-weight: unset;
  }
}

sup {
  font-size: 0.66em;
}

hr {
  border: none;
  border-bottom: 0.1em solid var(--sand);
}

.backlink {
  margin-block: var(--margin) 0;
}

figcaption,
.caption {
  max-width: var(--c6);
  margin-block: var(--gap);
  width: 100%;
  color: var(--black-40);
  font-size: 1rem;
  margin-left: auto;
  margin-right: auto;
  &::after {
    margin-top: 0.5em;
    display: block;
    content: "";
    width: 4rem;
    border-bottom: 0.2em solid var(--sand);
  }
}

img {
  border-radius: 1.25em;
  &.small {
    border-radius: 0.5em;
  }
}

figure {
  margin-block: var(--margin);
  display: flex;
  flex-direction: column;

  img {
    margin-inline: auto;
  }
}

p,
a:not([class]),
ul,
ol {
  margin-block: var(--p);
}

h1,
h2,
h3,
h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  line-height: 1.28;
  margin-block: 2em 1rem;
}

h1 {
  margin-top: var(--margin);
  font-size: 2.5rem;
}

.main > .container:first-of-type > h1:first-of-type {
  margin-top: var(--margin-s);
}

h2,
h3,
h4 {
  font-size: 1.375rem;
}

h2.decorated {
  font-size: 1.875rem;
  @media (--media-md) {
    margin-left: 5rem;
  }
}

.page-type--home {
  h2.decorated {
    margin-left: 0;
  }
}

/* .main > .container:first-of-type > h1:first-of-type {
  margin-top: var(--spacing);
} */
.main > .container:first-of-type > h1:first-of-type,
.decorated {
  &::before {
    content: "";
    display: block;
    width: 16rem;
    margin-bottom: var(--p);
    border-top: 0.2rem solid var(--sand);
  }
}

.responsive-embed {
  aspect-ratio: 16 / 9;
  position: relative;
}

.responsive-embed > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
