.subheader.prose {
  position: relative;
  padding-bottom: 0;
}

.subheader__title {
  font-weight: bold;
  font-size: 2.5rem;
  margin-block: unset;
  color: var(--white);
  text-shadow:
    1px 0px 0px var(--night),
    -1px 0px 0px var(--night),
    0px 1px 0px var(--night),
    0px -1px 0px var(--night),
    1px 1px 0px var(--night),
    -1px -1px 0px var(--night),
    -1px 1px 0px var(--night),
    1px -1px 0px var(--night);
}
.submenu.prose {
  margin-top: var(--margin-s);
}
.featured-publication ~ .submenu.prose {
  margin-top: calc(var(--margin-s) - var(--p));
}

@media (--media-lg) {
  .subheader.prose {
    max-width: calc(2 * 7.5rem + 2 * var(--p) + var(--c8));
    padding: 0;
  }
  .subheader__title {
    margin-top: var(--margin-s);
    font-size: 7.5rem;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    white-space: nowrap;
  }
  .subheader__title__wrap {
    position: absolute;
  }
}
