.featured-publication {
  .button {
    color: var(--white);
    border-color: var(--white);
    background-color: var(--sand);
    width: 100%;
    justify-content: center;
    padding-block: 0.2em;
    font-size: 1rem;
    margin-block: 0;
  }
}

.publication-container {
  @media (--media-md) {
    display: grid;
    justify-content: center;
  }
  .publication {
    margin-bottom: 15px;
    max-width: var(--c-prose);
    @media (--media-md) {
      width: var(--c-prose);
    }
  }
}

.publication-grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  margin-block: var(--margin-s) var(--margin-l);
  .buttons {
    width: 100%;
  }
}

.publication {
  flex: 1 1 var(--c4);

  display: block;
  padding: 2rem 2rem;
  background-color: var(--antique);
  border-radius: 1.25rem;

  margin-block: 0;

  display: flex;
  align-items: flex-start;
  gap: var(--spacing);

  h3 {
    margin-block: 0.25em;
    font-size: 1em;
  }
  p {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .publication__preview {
    flex-shrink: 0;
    display: none;
    margin-left: auto;
  }
  img {
    border-radius: 0;
  }
}

@media (--media-md) {
  .publication {
    .publication__preview {
      display: block;
    }
  }
}

.publication-list {
  margin-block: var(--margin);
}

.extranet-file {
  margin-bottom: -45px !important;
}

div#result {
  padding-bottom: 30px;
}
