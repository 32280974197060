.event-cell {
  display: flex;
  flex-wrap: wrap;
  gap: var(--p);
}

.event-list {
  margin-block: var(--margin);

  display: flex;
  flex-direction: column;
  gap: var(--margin-s);

  .event-cell {
    padding-bottom: var(--margin-s);
    border-bottom: 0.1em solid var(--black-20);
  }
}

.event-cell__date,
.event-detail__date {
  font-size: 1.375rem;
}

.event-cell__title {
  font-weight: bold;
  font-size: 1.625rem;
  margin-bottom: var(--p);
}

.event-cell__body {
  flex: 1 1 min(100%, 20rem);
}

.event-cell__image {
  flex: 0 0 min(100%, 12.5rem);
  img {
    width: 100%;
    border-radius: 0.75rem;
  }
}

.event-detail__date {
  margin-bottom: var(--margin);
}

.event-detail__lead {
  font-weight: bold;
}

.events-teaser {
  margin-block: var(--margin-s) var(--margin-l);

  .gallery {
    margin-block: var(--p);
    display: flex;
    justify-content: center;
    .gallery__contents {
      margin-block: 0;
      height: unset;
      width: 100%;
      @media (--media-lg) {
        width: 70%;
      }
    }

    figure {
      margin-block: 0;
      position: unset;
    }

    .gallery__content-wrap {
      margin-block: 0;
      padding: 1.5rem;
      display: flex;
      flex-wrap: wrap-reverse;
      gap: var(--gap);
      img {
        margin: auto;
        flex: 1 1 var(--c3);
        width: 70%;
        border-radius: 1rem;
      }
      .gallery__content-body {
        flex: 1 1 calc(50% - var(--gap));
        display: flex;
        flex-direction: column;
        gap: var(--gap);
      }
      .button {
        color: var(--black);
      }
      .date {
        font-size: 0.8em;
      }
      h3 {
        margin-block: 0;
      }
      p {
        margin-block: 0;
      }
    }
  }
}

/* to allign the button with
the events-teaser figure element */

.events-teaser__button-wrap {
  @media (--media-lg) {
    margin-inline: auto;
    width: 70%;
    display: flex;
    justify-content: left;
  }
}
