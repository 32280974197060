html {
  scroll-behavior: smooth;
  overscroll-behavior: none;

  font-size: clamp(0.75rem, 1.3vw, 1rem);
}

body {
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  color: var(--black);
  line-height: 1.4;
  font-size: 1.125rem;

  display: flex;
  flex-direction: column;
}
