.page-teaser {
  margin-block: var(--margin-s) var(--margin-l);
  display: flex;
  flex-wrap: wrap-reverse;
  gap: var(--gap);

  .page-teaser__body {
    flex: 1 1 var(--c3);
  }

  .page-teaser__images {
    flex: 1 1 var(--c5);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: var(--p);

    img {
      width: calc((100% - 2 * var(--p)) / 3);
    }

    img:nth-of-type(2) {
      margin-bottom: var(--margin);
    }
    img:nth-of-type(3) {
      margin-bottom: var(--margin-s);
    }
  }
}
