.quote {
  margin-bottom: var(--margin-s);
  position: relative;
}

.gallery__content-wrap {
  width: 100%;
  max-width: var(--c7);
  display: flex;
  flex-wrap: wrap;
  img {
    padding: var(--p);
    flex: 0 1;
    margin: auto;
    width: 20%;
  }
  .gallery__content-body {
    flex: 1 0 var(--c4);
    padding: var(--p);
    margin-right: auto;

    h3 {
      margin-top: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
  }

  @media (--media-md) {
    .quote {
      margin-top: var(--margin-s);
    }

    img {
      width: unset;
    }
  }
}
