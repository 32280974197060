.article-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-gap: var(--p);

  .article-cell {
    flex-direction: column;
  }
}

.article-list {
  margin-block: var(--margin);
  display: flex;
  flex-wrap: wrap;
  gap: var(--margin-s);

  .article-cell {
    &:not(:last-of-type) {
      border-bottom: 0.1em solid var(--sand);
    }
  }
}

.article-teaser {
  margin-block: var(--p) var(--margin-l);
}

.article-teaser .articles {
  display: flex;
  flex-wrap: wrap;
  gap: var(--margin-s);
  align-items: center;

  .featured-article {
    flex: 1 1 100%;
  }

  .article-teaser-list {
    flex-direction: row;
    border-bottom: 1px solid var(--black-20);

    &:last-of-type {
      border: none;
    }
  }

  .article-cell {
    display: flex;
    gap: var(--gap);

    padding-block: var(--p);
    margin-block: 0;

    .article-cell__image {
      flex: 0 1;
      min-width: clamp(100px, 33%, 400px);
    }

    .article-cell__body {
      flex: 0 1 auto;
      margin-bottom: 0;
    }

    .article-cell__title {
      margin-top: 0;
    }

    .authors {
      font-size: 1rem;
    }

    &.featured {
      flex-direction: column;
    }
  }

  .article-list {
    flex: 1 1 66%;
    margin-block: 0;
    gap: 0;

    .article-cell__title {
      font-size: 1.33em;
      .icon {
        --size: 0.8em;
      }
    }

    .article-cell__image {
      img {
        border-radius: 0.75rem;
      }
    }
  }

  .button-wrap {
    width: 100%;
  }
}

@media (--media-lg) {
  .article-teaser .articles {
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}

@media (--media-md) {
  .article-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .article-teaser .articles {
    .featured-article {
      border-bottom: none;
    }

    .article-teaser-list {
      flex-direction: row;
    }
  }
}

.article-cell__image {
  width: 100%;
}

.article-cell__image {
  img {
    width: 100%;
  }
}

.article-cell__title {
  font-weight: bold;
  font-size: 1.5em;
  line-height: 1.2;
  margin-top: var(--gap);
}

.article-cell__body {
  overflow-wrap: break-word;
  margin-bottom: var(--margin-s);
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.article-detail .authors {
  font-weight: bold;
}

.article-detail__title {
  margin-bottom: var(--margin-s);
}

.article-detail__lead {
  font-weight: bold;
  margin-bottom: var(--margin-s);
}

.article-cell__image {
  position: relative;
}

.button.button--icon.featured-pin {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: var(--sand);
  background-color: var(--white);
  border-color: var(--sand);
  padding: 0.625rem;
  gap: 0.5rem;
}
