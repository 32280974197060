.menu {
  margin-top: var(--p);
  & > * {
    padding-top: var(--p);
    padding-bottom: var(--p);
  }

  a {
    margin: 0;
    font-size: 1.25rem;
    color: var(--black);
    --icon-color: var(--black);
    &.active {
      font-weight: bold;
      color: var(--sand);
      --icon-color: var(--sand);
    }
  }
}

.menu .menu__top {
  display: flex;
  width: 100%;
  align-items: center;
  & > * {
    flex: 1 1 33%;
  }
  a {
    font-size: 1rem;
  }
}

.menu__top--right {
  display: flex;
  color: var(--sand);
  flex-direction: column;
  align-items: flex-end;
  gap: var(--spacing);
}

.menu__logo {
  img {
    margin-left: auto;
    margin-right: auto;

    width: 5rem;
    height: 5rem;
  }
}
.menu__languages {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing);
}

.menu + div {
}

.menu__menu {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: var(--margin-s);

  border-top: 0.1em solid var(--sand);
  border-bottom: 0.1em solid var(--sand);

  @media (--media-sm-only) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.submenu {
  padding-block: 0;
  /* &::after {
    content: "";
    display: block;
    width: 16rem;
    border-bottom: 0.2em solid var(--sand);
  } */
}

.submenu__menu {
  /* margin-bottom: var(--margin-s); */
  display: flex;
  flex-wrap: wrap;
  gap: var(--margin-s);
}

/* .menu__toggle {
  display: block;
  width: 1.75rem;
  height: 1.5rem;
  margin: 1rem;
  cursor: pointer;
} */

/* .menu__close {
  display: none;
} */

/* #expand-menu {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  position: absolute;
} */

/* #expand-menu:checked {
  + .menu {
    z-index: var(--z-top);
    transition: width 0.3s ease-out, height 0.3s ease-out;

    .menu__close {
      display: block;
    }
    .menu__open {
      display: none;
    }
    .menu__menu {
      opacity: 1;
      transition: opacity 0.2s;
      transition-delay: 0.3s;
    }
  }
} */

/* .menu__logo {
  display: block;
} */

/* @media (--media-sm-only) {
  .menu {
    top: 0;
    right: 0;
    left: 0;
  }

  .menu + div {
  }

  .menu__logo {
    display: none;
  }

  .menu__menu {
    left: 1rem;
    right: 1rem;
  }

  #expand-menu:checked {
    + .menu {
      height: 95vh;
      .menu__close {
        display: block;
      }
      .menu__open {
        display: none;
      }
    }
  }
} */
