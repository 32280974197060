.hide {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.bold {
  font-weight: bold;
}

.small {
  font-size: 0.8em;
}

.rule {
  margin: var(--p) 0;
  border-bottom: 0.1em solid var(--black);
  opacity: 0.4;
}

.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;

  &.skip-link:focus-visible {
    height: auto;
    width: auto;
    left: 5rem;
    top: 5rem;
  }
}

.print-only {
  display: none;

  @media print {
    display: unset;
  }
}

/*
Soon!
:has(.grow-v) {
  display: flex;
  flex-direction: column;
}
.grow-v {
  flex: 1 0 auto;
}
*/
