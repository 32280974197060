.icon {
  --size: 2em;
  width: var(--size);
  height: var(--size);
}

.inline-icon {
  display: inline-block;

  .icon {
    margin-inline: 0 var(--spacing-s);

    display: inline;
    vertical-align: middle;
    --size: 1em;
    color: var(--icon-color, var(--sand));
    transform: translateY(-0.1em); /* baseline correction */
  }
}

.inline-icon.inline-icon--right {
  .icon {
    margin-inline: var(--spacing-s) 0;
  }
}
