.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: var(--p);
}

button {
  padding: 0;
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  appearance: none;
}

.button {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  user-select: none;
  line-height: 1;
  text-decoration: none;
  padding: 1em 1.5em;
  color: var(--night);
  border: 0.125em solid var(--night);
  cursor: pointer;
  font-weight: bold;
  transition: border-radius var(--general-transition-speed), background-color
    var(--general-transition-speed);
  border-radius: 0.75em;
}

.button--icon {
  display: inline-flex;
  align-items: center;
  gap: 1em;
  .icon {
    --size: 1.2em;
  }
}

.button--clean {
  border: none;
  padding: 0;
}

.button:not(.button--clean) {
  &:focus-visible,
  &:hover {
    color: var(--white);
    background-color: var(--night);
  }
}

.button-plugin {
  margin-block: 1em;
}
